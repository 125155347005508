@import '../../../../partials/colors';
@import '../../../../partials/spacing';
@import '../../../../partials/fonts';

.RoomCard {
  min-width: 360px;

  &:hover .card-title {
    color: color(pink, base);
  }

  &:hover .header > .pricing-box {
    background-color: color(pink, base, .75);
    -webkit-transform: translateX(40%) translateY(50%) rotate(-45deg);
    transform: translateX(40%) translateY(50%) rotate(-45deg);
  }

  &:hover .header > .pricing-container {
    bottom: 0;
    right: 0;
    opacity: 1;
    visibility: visible;
  }

  .header {
    position: relative;
    overflow: hidden;

    img {
      max-width: 100%;
      display: block;
    }

    .pricing-box {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      margin: 0;
      background-color: color(pink, base, 0);
      -webkit-transform: translateX(50%) translateY(50%) rotate(-45deg);
      transform: translateX(50%) translateY(50%) rotate(-45deg);
      transition: .6s;
    }

    .pricing-container {
      position: absolute;
      bottom: -25px;
      right: -25px;
      padding: 0 20px 30px;
      transition: .6s;
      opacity: 0;
      visibility: hidden;

      p {
        margin: 0;
        color: color(white, base);
        text-align: right;
        font-weight: 600;
      }

      .pricing {
        font-size: 2rem;
        padding-bottom: 5px;
      }
    }
  }

  .body {
    .card-title {
      transition: .6s;
    }
    
    .description {
      height: 100px;
      display: flex;
      align-items: center
    }
  }

  .buttons {
    padding: space(7) 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .Rooms {
    padding: 0;
  }
  .RoomCard {
    margin: 1vh 0;
    .buttons {
      grid-template-columns: 1fr;
      display: flex;
      justify-content: center;
      align-items: center;
      .Button {
        width: 80%;
      }
    }
  }
}