@import '../../../partials/colors';
@import '../../../partials/spacing';

.Super {
  position: relative;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 330px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';

    // @supports (backdrop-filter: blur(3px) brightness(.6)) {
    //   backdrop-filter: blur(3px) brightness(.6);
    // }

    // @supports not (backdrop-filter: blur(2px) brightness(.6)) {
    //   background-color: color(black, base, .5);
    // }

    background-color: color(black, base, .5);
    
    z-index: 0;
  }
  
  .inner {
    z-index: 1;
    .title, .route, .route > * {
      text-align: center;
      color: color(white, base);
      text-decoration: none;
    }

    .route {
      font-size: 110%;

      .link {
        padding: 0 15px;
      }

      .link:not(:first-child) {
        &::before {
          content: '/';
          position: absolute;
          left: 0;
        }

        position: relative;
      }
    }
  }
}