.Position {
  display: flex;
}

.pos-align-center {
  justify-content: center;
}

.pos-align-left {
  justify-content: flex-start;
}

.pos-align-right {
  justify-content: flex-end;
}

.pos-vertical-align-middle {
  align-items: center;
}

.pos-flex-direction-column {
  flex-direction: column;
}