@import '../../../partials/colors';
@import '../../../partials/fonts';
@import '../../../partials/spacing';

.Button {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: color(pink, base);
  border: 0;
  color: color(white, base) !important;
  font-weight: 600;
  transition: .4s;
  font-family: font(main);
  line-height: 1rem;
  box-sizing: border-box;
  font-size: 1rem;
  padding: space(6);
  width: 100%;
  border-radius: space(3);
  box-shadow: 0px 5px 10px color(black, base, .25);

  &.menu-item {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
  
  &:hover {
    background-color: color(gray, darker) !important;
  }

  &.dark {
    background-color: color(gray, darker);
    
    &:hover {
      background-color: color(pink, base);
    }
  }
}