@import '../../../partials/spacing';
@import '../../../partials/colors';

@media only screen and (max-width: 768px) {
  .Section {
    padding: space(13) space(1);
  }

  .SectionHeader {
    h2 {
      padding: 0;
      text-align: center;
    }
  }
}

@media only screen and (min-width: 769px) {
  .Section {
    padding: space(13) space(12) + space(12) + space(12);
    border-bottom: 1px solid color(gray, base);
    width: 100%;
    box-sizing: border-box;
  }
  
  .SectionHeader {
    padding: space(5) 0 space(8);

    h2 {
      padding: space(1);
    }
  }
}