@import '../../../../partials/colors';
@import '../../../../partials/spacing';

.ImageCard {
  position: relative;
  overflow: hidden;
  height: 280px;
  margin-bottom: space(5);

  &:hover .body > .card-title {
    padding: space(8) + space(1);
  }

  &:hover .header img {
    transform: scale(1.16);
  }

  &:hover .body::before {
    height: calc(100% - 35px);
    opacity: 1;
  }

  &:hover .body::after {
    width: calc(100% - 33px);
    opacity: 1;
  }

  &.closed {
    
    img {
      transform: scale(1);
    }
  }

  transition: .5s;
  &.opened {
    // transform: scale(1.5);
    overflow: visible;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    
    img {
      position: relative;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      max-width: 100%;
    }

    .header::before {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: color(gray, darkest, .5);
    }

    &:hover .header img {
      transform: scale(1);
    }

    &:hover .body::before {
      opacity: 0;
    }
  
    &:hover .body::after {
      opacity: 0;
    }
  }

  .header {
    height: 100%;
    width: 100%;
    position: relative;
    
    img {
      position: absolute;
      height: 100%;
      transition: .75s;
    }
  }
  
  .body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: color(black, base, .35);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: calc(100% - 35px);
      height: 40%;
      border: 1px solid color(white, base);
      border-top: 0;
      border-bottom: 0;
      transition: .75s;
      opacity: 0;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 40%;
      height: calc(100% - 35px);
      border: 1px solid color(white, base);
      border-right: 0;
      border-left: 0;
      opacity: 0;
      transition: .75s;
    }
    
    .card-title {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      color: color(white, base);
      padding: space(5);
      box-sizing: border-box;
      transition: .75s;
      z-index: 100;
    }
  }
}

