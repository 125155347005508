@import '../../partials/colors';
@import '../../partials/spacing';
@import '../../partials/fonts';

.Header {
  z-index: 999;
}

.top {
  margin: 0 space(12) + space(12) + space(12);
  background-color: color(white, base);
  display: grid;
  grid-template-columns: 12fr 2fr;
  border-bottom: 1px solid color(gray, base);
  align-items: center;

  h2 {
    padding: space(5) 0;
    color: color(gray, dark, .8);
    font-size: .9rem;
    font-weight: normal;
  }

  .social-icons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;

    & > * {
      padding: 8px;
      width: 15px;
      height: 15px;
      overflow: visible;
      border: 1px solid color(gray, dark, 1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: color(gray, dark, 1);
      transition: all .4s ease-in-out;

      &:hover {
        background-color: color(pink, base);
        border-color: color(pink, base);
        color: color(white, base);
      }
    }
  }
}

.mid {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: space(5) 0;
  .img-container {
    width: 140px;
    height: 140px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    img {
      height: 100%;
    }
  }
}

.bot {
  z-index: 9999;
  height: 55px;
  background-color: color(white, base);
  padding: 0 space(12) + space(12) + space(12);
  border-right: none;
  border-left: none;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  box-shadow: 0px 0px 5px color(black, base, .01),
              0px 1px 3px color(black, base, .028),
              0px 0px 0px 1px color(black, base, .028);
  display: grid;
  grid-template-columns: 2fr 9fr 2fr;

  .items-grid {
    width: 100%;
    height: 100%;
  }

  .menu-item {
    height: 100%;
    box-sizing: border-box;
    transition: .4s;
    position: relative;
    display: flex;
    flex-basis: 100%;
    font-weight: 600;
    color: color(gray, darker);
    cursor: pointer;
    text-decoration: none;
    &.with-separator::before {
      content: '';
      position: absolute;
      top: 50%;
      width: 2px;
      height: 16px;
      background: #ded3d3;
      left: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    &:hover {
      background-color: color(gray, light, .5);
    }
  }

  .menu-items, .menu-item {
    align-items: center;
    justify-content: center;
  }

  .menu-items {
    display: flex;
    flex: 1 1 0;
    flex-direction: row;
  }
}

.BookForm {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1001;
  background-color: color(black, base, .2);
  transition: .4s;
  
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

}
.form {
  padding: space(8) space(12);
  box-sizing: border-box;
  background-color: color(gray, variant1);
  box-shadow: 0px 2px 5px color(black, base, .2);
  display: flex;
  flex-wrap: wrap;
  max-width: 35%;
  border-radius: space(3);
  
  &.full-width {
    max-width: 100%;
  }

  h3 {
    padding-bottom: space(5);
    font-size: 1.5rem;
    font-weight: bold;
  }

  .input-control {
    box-sizing: border-box;
    font-weight: 600;


    button {
      margin-top: space(6);
    }

    #adultos, #ninos {
      width: 50%;
    }

    .react-datepicker-wrapper {
      width: 50%;
    }

    #adultos, #ninos, #fechaEntrada, #fechaSalida {
      box-shadow: none;
      border-radius: 0;
    }
  }

  .form-numbers {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 1.8rem;
      background: color(gray, dark, .15);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  .form-dates, .form-numbers {
    box-shadow: 0px 5px 10px color(black, base, .08);
    padding: 0;
    border-radius: space(2);
    overflow: hidden;
    .react-datepicker-wrapper:nth-child(2), .react-datepicker-wrapper:nth-child(3) {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        width: 2px;
        height: 1.8rem;
        background: color(gray, dark, .15);
        z-index: 1000;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
    
    .react-datepicker-popper {
      z-index: 1000;
    }
    
  }
}

.slider {
  .form-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding-right: space(12) + space(12) + space(12);
  }

  .form {
    padding: space(8) space(12);
    background-color: color(black, base, .5);
    color: color(white, base);
    box-sizing: border-box;
    box-shadow: 0px 2px 5px color(black, base, .2);
    display: flex;
    flex-wrap: wrap;
    border-radius: space(3);
    max-width: 35%;

    .input-control {
      label {
        color: color(white, base);
      }

      button {
        margin: space(2) 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Header {
    width: 100%;
  }
  .top {
    margin: 0 space(5);
  }

  .bot {
    padding: 0 space(5);
    height: auto;
    grid-template-columns: 1fr 1fr 10fr;
    .menu-items {
      flex-direction: column;
      transition: .4s;
      position: relative;

      &.visible {
        visibility: visible;
        height: auto;

        .menu {
          visibility: hidden;
          display: none;
        }
      }
      &.hidden {
        visibility: hidden;
        height: 55px;

        .menu {
          visibility: visible;
          display: initial;
          width: 100%;
          padding: 8px;
          position: relative;
          top: 0;
        }
      }
      .menu-item {
        width: 100%;
        padding: 8px;
        &.with-separator::before {
          all: unset;
        }
      }
    }
  }

  .form {
    max-width: 90%;
    padding: 5px;
  }

  .slider {
    .form-container {
      padding-right: space(5);

      .form {
        max-width: 90%;
        padding: 5px;
      }
    }
  }

  .show-on-xs {
    display: initial;
    visibility: visible;
  }

  .social-icons a {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 769px) {
  .show-on-xs {
    display: none;
    visibility: none;
  }

  .menu {
    visibility: hidden;
    display: none;
  }


}
