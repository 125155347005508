$fonts: (
  main: ('Montserrat', sans-serif),
  serif: ('Merriweather', serif),
);

@function font($font) {
  @if map-has-key($map: $fonts, $key: $font) {
    @return map-get($map: $fonts, $key: $font);
  }

  @return map-get($map: $fonts, $key: main);
}