@import './partials/colors';
@import './partials/fonts';
@import './partials/spacing';

html, body {
  margin: 0;
  background-color: $white;
  font-family: font(main);
  color: color(gray, darkest);
  overflow-x: hidden;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 600;
  font-family: font(main);
}

main {
  min-height: 150vh;
}

.col-half {
  max-width: 50%;
  flex: 50%;
}

.col-full {
  max-width: 100%;
  flex: 100%;
}

@media (max-width: 600px) {
  .col-half.col-xs-full {
    flex: 100%;
    max-width: 100%;
  }
}

input, select, .react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
  box-sizing: border-box; 
  font-family: font(main);
  line-height: 1.2rem;
}

.input-control {
  box-sizing: border-box;
  font-weight: 600;
  padding: space(3) 0;
  
  label {
    display: block;
    padding: space(2) space(4);
    color: color(gray, dark, .7);
  }

  input, select, textarea {
    border: none; 
    padding: space(5) space(4);
    border-radius: space(2);
    box-shadow: 0px 5px 10px color(black, base, .08);
    outline: none;
    font-size: 1.2rem;
    font-family: font(main);
    
    &[type="number"] {
      text-align: center; 
    }
  }

  textarea {
    min-height: 7rem;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
}

.no-padding {
  padding: 0;
}

.padding-top {
  padding-top: space(5);
  padding-bottom: 0;
}

.padding-bot {
  padding-bottom: space(5);
}

.pointer {
  cursor: pointer;
}