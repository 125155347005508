@import '../../../partials/spacing';

.Grid {
  display: grid;
  justify-items: center; 
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-column-gap: space(11);
  grid-row-gap: space(11);

  img {
    max-width: 100%;
    display: block;
  }
}