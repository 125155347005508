@import '../../../partials/fonts';
@import '../../../partials/spacing';

.variant-title {
  font-family: font(serif);
  font-weight: bold;
  font-size: 3rem;
  padding: space(7) 0;
  margin-bottom: 3vh;
}

.variant-sub-title {
  font-family: font(serif);
  font-weight: 600;
  font-size: 2rem;
  padding: space(7) 0;
}

.variant-sub-title-2 {
  font-family: font(main);
  font-weight: normal;
  font-size: 1.5rem;
  padding: space(7) 0;
  line-height: 2.5rem;
}

.variant-body {
  font-family: font(main);
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.4rem;
}

.variant-body-bold {
  font-family: font(main);
  font-weight: 600;
}

.variant-header {
  font-family: font(main);
  text-transform: uppercase;
  font-weight: normal;
  line-height: 1.7rem;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-justified {
  text-align: justify;
}

@media only screen and (max-width: 768px) {
  .variant-title {
    font-size: 2.5em;
  }
  .variant-body {
    text-align: justify;
  }
}